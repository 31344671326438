import { api, getAuthorizationBearerHeader } from "../../../api";
import createExternalStore from "../../../common/utils/createExternalStore";
import { CORE_API_HOSTNAME } from "../../../env";
import { Causes, errorExternalStore } from "../../../errors";
import { expiresAt } from "../../../helpers/jwt";
import { sleep } from "../../../helpers/sleep";
import type {
    AuthToken,
    AuthenticatedAccountUser,
} from "../../../types/management-auth";
import { getAccounts, logout } from "../../cognito";
import {
    clearPartner,
    getPartner,
    setCognitoSession,
    setLastAccountId,
    setPartner,
} from "../../localStorage";
import type {
    AuthenticatedUser,
    ChangeMfaSmsModalStates,
    ChangeMfaTotpModalStates,
    ChangePasswordModalStates,
    PartnerUser,
} from "../../types";

export interface State extends AuthenticatedUser {
    tokens: {
        [accountId: string]: string;
    };
    isLoadingTokens: boolean;
    isLoadingSubAccountTokens: boolean;
    exchangeTokenPartnerId: string | undefined;
    seq: number;
    changePasswordState: ChangePasswordModalStates;
    changeMfaSmsState: ChangeMfaSmsModalStates;
    changeMfaTotpState: ChangeMfaTotpModalStates;
    partner_user: PartnerUser | undefined;
}

const initialState: State = {
    cognito_access_token: "",
    cognito_expires: "",
    exchangeTokenPartnerId: undefined,
    account_user: {
        id: "",
        accounts: [],
    },
    tokens: {},
    isLoadingTokens: false,
    isLoadingSubAccountTokens: false,
    seq: 0,
    changePasswordState: "pristine",
    changeMfaSmsState: "pristine",
    changeMfaTotpState: "pristine",
    partner_user: undefined,
};

const authStore = createExternalStore(
    initialState,
    {
        setAuthenticatedUser: (
            state,
            action: {
                accountUser: AuthenticatedUser;
                exchangeTokenPartnerId?: string;
            },
        ) => {
            const [partner_user, exchangeTokenPartnerId] = getPartner();
            logoutOnExpiry(action.accountUser, action.exchangeTokenPartnerId);
            return {
                ...state,
                ...action.accountUser,
                partner_user,
                exchangeTokenPartnerId,
                isLoadingTokens: true,
            };
        },
        setInspectAuthenticatedUser: (
            state,
            action: {
                accountUser: AuthenticatedUser;
                exchangeTokenPartnerId: string;
            },
        ) => {
            setPartner(state, action.exchangeTokenPartnerId);
            return {
                ...state,
                ...action.accountUser,
                exchangeTokenPartnerId: action.exchangeTokenPartnerId,
                partner_user: { ...state },
            };
        },
        setLeaveInspect: (state) => {
            if (state.partner_user) {
                clearPartner();
                setCognitoSession({
                    cognito_access_token:
                        state.partner_user.cognito_access_token,
                    cognito_expires: state.partner_user.cognito_expires,
                });
            }
            return {
                ...state,
                ...(state.partner_user || {}),
                partner_user: undefined,
                exchangeTokenPartnerId: undefined,
            };
        },
        updateAuthenticatedUser: (
            state,
            action: {
                accountId: string;
            },
        ) => {
            refetchAuthenticatedUser(state, action.accountId);
            return {
                ...state,
            };
        },
        updateAuthenticatedUserResult: (
            state,
            action: {
                authenticatedUser: AuthenticatedUser;
                accountId: string;
            },
        ) => {
            refetchTokens(action.accountId);
            return {
                ...state,
                ...action.authenticatedUser,
            };
        },
        loadTokens: (
            state,
            action: {
                accountId: string;
                showLoading: boolean;
            },
        ) => {
            fetchAccountTokens(state, action.accountId);
            return {
                ...state,
                isLoadingTokens: action.showLoading,
                seq: state.seq + 1,
            };
        },
        loadTokensResponse: (
            state,
            action: {
                accounts: {
                    accountId: string;
                    token: string;
                }[];
            },
        ) => {
            return {
                ...state,
                tokens: action.accounts.reduce(
                    (acc, x) => ({ ...acc, [x.accountId]: x.token }),
                    {},
                ),
                isLoadingTokens: false,
            };
        },
        loadSubAccountTokens: (
            state,
            action: {
                accountId: string;
                subAccountId: string;
            },
        ) => {
            fetchSubAccountTokens(action.accountId, action.subAccountId);
            return {
                ...state,
                isLoadingSubAccountTokens: true,
            };
        },
        loadSubAccountTokensResponse: (
            state,
            action: {
                accounts: {
                    accountId: string;
                    token: string;
                }[];
            },
        ) => {
            return {
                ...state,
                isLoadingSubAccountTokens: false,
                tokens: {
                    ...state.tokens,
                    ...action.accounts.reduce(
                        (acc, x) => ({ ...acc, [x.accountId]: x.token }),
                        {},
                    ),
                },
            };
        },
        openChangePassword: (state) => {
            return {
                ...state,
                changePasswordState: "pristine",
            };
        },
        changePassword: (
            state,
            action: {
                previous_password: string;
                proposed_password: string;
            },
        ) => {
            fetchPostPassword(state, {
                previous_password: action.previous_password,
                proposed_password: action.proposed_password,
            });
            return {
                ...state,
                changePasswordState: "in-flight",
            };
        },
        changePasswordResponse: (
            state,
            action: {
                result: ChangePasswordModalStates;
            },
        ) => {
            return {
                ...state,
                changePasswordState: action.result,
            };
        },
        openAuthenticatedUserSettings: (state) => {
            return {
                ...state,
                changeMfaSmsState: "pristine",
                changeMfaTotpState: "pristine",
            };
        },
        changeAuthenticatedUserMfaSms: (
            state,
            action: {
                enabled: boolean;
                phone_number?: string;
            },
        ) => {
            fetchPutMfaSms(state, {
                enabled: action.enabled,
                phone_number: action.phone_number,
            });
            return {
                ...state,
                changeMfaSmsState: "in-flight",
            };
        },
        changeAuthenticatedUserMfaSmsResponse: (
            state,
            action: {
                result: ChangeMfaSmsModalStates;
            },
        ) => {
            return {
                ...state,
                changeMfaSmsState: action.result,
            };
        },
        changeAuthenticatedUserMfaTotp: (
            state,
            action: {
                enabled: boolean;
                user_code?: string;
            },
        ) => {
            fetchPutMfaTotp(state, {
                enabled: action.enabled,
                user_code: action.user_code,
            });
            return {
                ...state,
                changeMfaTotpState: "in-flight",
            };
        },
        changeAuthenticatedUserMfaTotpResponse: (
            state,
            action: {
                result: ChangeMfaTotpModalStates;
            },
        ) => {
            return {
                ...state,
                changeMfaTotpState: action.result,
            };
        },
    },
    "AUTH",
);

const periodicallyCheckExpiry = async (expiresAt: number) => {
    while (expiresAt > new Date().getTime()) {
        await sleep(60 * 1000);
    }
};

const getAccountIdsForAccount = (
    accountUser: AuthenticatedAccountUser,
    accountId: string | undefined,
) => {
    const withoutPrefix = accountId?.substring(1);
    const matchingAccounts = accountUser.accounts.filter(
        (a) => a.account_id.substring(1) === withoutPrefix,
    );
    return matchingAccounts.map((a) => a.account_id);
};

const grant: AuthToken = {
    grant_type: "account_user",
};

const fetchExchangeToken = async (accountId: string, subAccountId: string) => {
    return await api({
        method: "POST",
        url: `${CORE_API_HOSTNAME}/v1/accounts/${accountId}/auth/exchange_token`,
        accountId: accountId,
        json: {
            account_id: subAccountId,
        },
        handlers: {
            200: (response: any) => response?.access_token || null,
            403: () => null,
        },
    });
};

const fetchPasswordResult = (
    result: "success" | "wrong_password" | "error" | "rate_limit",
) =>
    authStore.dispatch("changePasswordResponse", {
        result,
    });

const fetchMfaSmsResult = (
    result: "success" | "invalid_phone" | "error" | "rate_limit",
) =>
    authStore.dispatch("changeAuthenticatedUserMfaSmsResponse", {
        result,
    });

const fetchMfaTotpResult = (
    result: "success" | "invalid_user_code" | "error" | "rate_limit",
) =>
    authStore.dispatch("changeAuthenticatedUserMfaTotpResponse", {
        result,
    });

// #region Side-effects

const logoutOnExpiry = async (
    accountUser: AuthenticatedUser,
    exchangeTokenPartnerId: string | undefined = undefined,
) => {
    const cognitoToken = accountUser.cognito_access_token;
    const expiry = expiresAt(cognitoToken);
    const buffer = 5 * 60 * 1000;
    await periodicallyCheckExpiry(expiry.getTime() - buffer);
    errorExternalStore.dispatch("setError", {
        cause: Causes.AccessTokenExpired,
    });
    return {
        cause: Causes.AccessTokenExpired,
    };
};

const fetchAccountTokens = async (state: State, accountId: string) => {
    const accountIds = getAccountIdsForAccount(state.account_user, accountId);
    const promises = accountIds.map((id) =>
        api<{
            accountId: string;
            token: string;
        } | null>({
            method: "POST",
            url: `${CORE_API_HOSTNAME}/v1/accounts/${id}/auth/token`,
            json: grant,
            headers: getAuthorizationBearerHeader(state.cognito_access_token),
            accountId: "none",
            handlers: {
                200: (responseJson: any) => {
                    if (responseJson?.access_token) {
                        const token = responseJson.access_token;
                        return {
                            accountId: id,
                            token,
                        };
                    } else {
                        throw new Error(
                            "200 response does not contain access_token",
                        );
                    }
                },
                403: () => {
                    return null;
                },
            },
        }),
    );
    const responses = await Promise.all(promises);
    const accounts = responses.filter((r) => r) as {
        accountId: string;
        token: string;
    }[];
    setLastAccountId(accountId);
    authStore.dispatch("loadTokensResponse", {
        accounts,
    });
};

const refetchAuthenticatedUser = async (state: State, accountId: string) => {
    const authenticatedAccountUser = await getAccounts(
        state.cognito_access_token,
    );
    if (authenticatedAccountUser) {
        authStore.dispatch("updateAuthenticatedUserResult", {
            authenticatedUser: {
                account_user: authenticatedAccountUser,
            } as AuthenticatedUser,
            accountId,
        });
    } else {
        // unable to authenticate user so we force a logout so the user can login again
        logout(false);
    }
};

const refetchTokens = async (accountId: string) => {
    return authStore.dispatch("loadTokens", {
        accountId,
        showLoading: false,
    });
};

const fetchSubAccountTokens = async (
    accountId: string,
    subAccountId: string,
) => {
    const exchangeToken = await fetchExchangeToken(accountId, subAccountId);
    if (!exchangeToken) {
        return authStore.dispatch("loadSubAccountTokensResponse", {
            accounts: [],
        });
    }
    const nonPrefixedSubAccount = subAccountId.substring(1);
    const promises = ["P", "T"]
        .map((prefix) => `${prefix}${nonPrefixedSubAccount}`)
        .map((id) =>
            api<{
                accountId: string;
                token: string;
            } | null>({
                method: "POST",
                url: `${CORE_API_HOSTNAME}/v1/accounts/${id}/auth/token`,
                json: grant,
                headers: getAuthorizationBearerHeader(exchangeToken),
                accountId: "none",
                handlers: {
                    200: (responseJson: any) => {
                        if (responseJson.access_token) {
                            const token = responseJson.access_token;
                            return {
                                accountId: id,
                                token,
                            };
                        } else {
                            throw new Error(
                                "200 response does not contain access_token",
                            );
                        }
                    },
                    403: () => {
                        return null;
                    },
                },
            }),
        );
    const responses = await Promise.all(promises);
    const accounts = responses.filter((r) => r) as {
        accountId: string;
        token: string;
    }[];
    authStore.dispatch("loadSubAccountTokensResponse", {
        accounts,
    });
};

const fetchPostPassword = async (
    state: State,
    payload: {
        previous_password: string;
        proposed_password: string;
    },
) => {
    // Get account tokens for account, both test and prod if exists
    return api({
        method: "POST",
        url: `${CORE_API_HOSTNAME}/v1/account/user/password`,
        json: payload,
        headers: getAuthorizationBearerHeader(state.cognito_access_token),
        accountId: "none",
        handlers: {
            201: () => fetchPasswordResult("success"),
            400: () => fetchPasswordResult("wrong_password"),
            401: () => fetchPasswordResult("error"),
            403: () => fetchPasswordResult("error"),
            429: () => fetchPasswordResult("rate_limit"),
            500: () => fetchPasswordResult("error"),
        },
    });
};

const fetchPutMfaSms = async (
    state: State,
    payload: {
        enabled: boolean;
        phone_number?: string;
    },
) => {
    // Get account tokens for account, both test and prod if exists

    return api({
        method: "PUT",
        url: `${CORE_API_HOSTNAME}/v1/account/user/mfa/sms`,
        json: payload.enabled ? payload : { enabled: payload.enabled },
        headers: getAuthorizationBearerHeader(state.cognito_access_token),
        accountId: "none",
        handlers: {
            201: () => fetchMfaSmsResult("success"),
            400: () => fetchMfaSmsResult("invalid_phone"),
            401: () => fetchMfaSmsResult("error"),
            403: () => fetchMfaSmsResult("error"),
            429: () => fetchMfaSmsResult("rate_limit"),
            500: () => fetchMfaSmsResult("error"),
        },
    });
};

const fetchPutMfaTotp = async (
    state: State,
    payload: {
        enabled: boolean;
        user_code?: string;
    },
) => {
    // Get account tokens for account, both test and prod if exists

    return api({
        method: "PUT",
        url: `${CORE_API_HOSTNAME}/v1/account/user/mfa/totp`,
        json: payload.enabled ? payload : { enabled: payload.enabled },
        headers: getAuthorizationBearerHeader(state.cognito_access_token),
        accountId: "none",
        handlers: {
            201: () => fetchMfaTotpResult("success"),
            400: () => fetchMfaTotpResult("invalid_user_code"),
            401: () => fetchMfaTotpResult("error"),
            403: () => fetchMfaTotpResult("error"),
            429: () => fetchMfaTotpResult("rate_limit"),
            500: () => fetchMfaTotpResult("error"),
        },
    });
};

export default authStore;
