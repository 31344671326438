import { Link } from "react-router-dom";
import styled from "styled-components/macro";

import {
    border,
    defaultRadius,
    distances,
    palette,
} from "../../styles/constants";

const ButtonLink = styled(Link)`
    padding: 10px 16px;
    border-radius: ${defaultRadius};
    color: white;
    background: ${palette.primary[500]};
    border: ${border.normal} solid ${palette.primary[500]};
    text-decoration: none;
    user-select: none;
    font-weight: 500;
    font-size: 14px;
    line-height: ${distances.small};
    cursor: pointer;
    min-height: 40px;
    display: inline-block;
    text-align: center;
    transition: all 0.15s;

    line-height: 20px;

    outline: 4px solid transparent;

    &:hover {
        background: ${palette.primary[600]};
        border: ${border.normal} solid ${palette.primary[600]};
    }

    &:focus {
        outline-color: ${palette.primary[100]};
        outline-offset: 0px;
    }

    &.has-icons {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
    }

    &:disabled, &.disabled {
        pointer-events: none;
        user-select: none;
        background: ${palette.primary[300]};
        border-color: ${palette.primary[300]};
        cursor: not-allowed;
    }

    &.tiny {
        min-width: auto;
        padding: 0;
        height: ${distances.normal};
        padding-left: ${distances.tiny};
        padding-right: ${distances.tiny};
    }

    &.tiny-768 {
        @media (max-width: 768px) {
            min-width: auto;
            padding: 0;
            height: ${distances.normal};
            padding-left: ${distances.tiny};
            padding-right: ${distances.tiny};
        }
    }

    &.full-width {
        width: 100%;
    }

    &.stretch {
        min-width: 100%;
    }

    &.small-1500 {
        @media (max-width: 1500px) {
            padding: 6px 12px;
            font-size: 12px;
            min-height: 20px;
        }
    }

    &.small-1800 {
        @media (max-width: 1800px) {
            padding: 6px 12px;
            font-size: 12px;
            min-height: 20px;
        }
    }

    &.small {
        padding: 6px 12px;
        font-size: 12px;
        min-height: 20px;
    }

    &.large {
        padding: 12px 20px;
        font-size: 16px;
        line-height: 24px;
    }

    &.default {
        border: ${border.normal} solid ${palette.neutral[200]};
        background: ${palette.white};
        color: ${palette.neutral[700]};
        &:hover {
            background: ${palette.neutral[50]};
            border: ${border.normal} solid ${palette.neutral[300]};
        }
        &:focus {
            border: ${border.normal} solid ${palette.neutral[300]};
        }
        &:active {
            background: ${palette.neutral[100]};
            color: ${palette.neutral[800]};
        }
        &:disabled, &.disabled {
            pointer-events: none;
            user-select: none;
            background: ${palette.neutral[50]};
            border: ${border.normal} solid ${palette.neutral[50]};
            color: ${palette.neutral[300]};
        }
    }

    &.alt {
        background: ${palette.primary[50]};
        color: ${palette.primary[500]};
        border: ${border.normal} solid ${palette.primary[50]};

        &:hover {
            background: ${palette.primary[100]};
            border: ${border.normal} solid ${palette.primary[100]};
        }
        &:focus {
            border: ${border.normal} solid ${palette.primary[300]};
        }
        &:active {
            background: ${palette.primary[300]};
            color: ${palette.primary[600]};
        }

        &:disabled, &.disabled {
            pointer-events: none;
            user-select: none;
          opacity: .5;
          pointer-events: none;
        }
        &.neutral {
            background: ${palette.neutral[50]};
            color: ${palette.neutral[900]};
            border: ${border.normal} solid ${palette.neutral[50]};

            .icon {
                background-color: ${palette.neutral[900]};
            }

            &:hover {
                background: ${palette.neutral[100]};
                border: ${border.normal} solid ${palette.neutral[100]};
            }
            &:focus {
                border: ${border.normal} solid ${palette.neutral[300]};
            }
            &:active {
                background: ${palette.neutral[300]};
                color: ${palette.neutral[900]};
                .icon {
                    background-color: ${palette.neutral[900]};
                }
            }
        }
    }

    &.red {
        background: ${palette.destructive[500]};
        border: ${border.normal} solid ${palette.destructive[500]};

        &:hover {
            background: ${palette.destructive[600]};
            border: ${border.normal} solid ${palette.destructive[600]};
        }
        &:active {
            background: ${palette.destructive[700]};
            border: ${border.normal} solid ${palette.destructive[700]};
        }
    }

    &.neutral {
        background: ${palette.neutral[500]};
        border: ${border.normal} solid ${palette.neutral[500]};
        &:hover {
            background: ${palette.neutral[600]};
            border: ${border.normal} solid ${palette.neutral[600]};
        }
        &:active {
            background: ${palette.neutral[700]};
            border: ${border.normal} solid ${palette.neutral[700]};
        }
    }

    &.outlined {
        background: transparent;
        color: ${palette.primary[500]};
        border: ${border.normal} solid ${palette.primary[500]};

        &:hover {
            background: transparent;
            border: ${border.normal} solid ${palette.primary[400]};
        }
        &:focus {
            border: ${border.normal} solid ${palette.primary[400]};
        }
        &:active {
            background: transparent;
            color: ${palette.primary[400]};
            border: ${border.normal} solid ${palette.primary[400]};
        }

        &:disabled, &.disabled {
            pointer-events: none;
            user-select: none;
          opacity: .5;
          pointer-events: none;
        }

        &.red {
            background: transparent;
            color: ${palette.destructive[500]};
            border: ${border.normal} solid ${palette.destructive[500]};

            &:hover {
                background: transparent;
                border: ${border.normal} solid ${palette.destructive[600]};
            }
            &:active {
                background: transparent;
                color: ${palette.destructive[700]};
                border: ${border.normal} solid ${palette.destructive[700]};
            }
        }
        &.neutral {
            background: transparent;
            color: ${palette.neutral[500]};
            border: ${border.normal} solid ${palette.neutral[500]};
            &:hover {
                background: transparent;
                border: ${border.normal} solid ${palette.neutral[600]};
            }
            &:active {
                background: transparent;
                color: ${palette.neutral[700]};
                border: ${border.normal} solid ${palette.neutral[700]};
            }
         }
         &.neutral-on-darkmode {
            background: transparent;
            color: ${palette.neutral[50]};
            border: ${border.normal} solid ${palette.neutral[50]};
            &:hover {
                background: transparent;
                border: ${border.normal} solid ${palette.neutral[100]};
            }
            &:active {
                background: transparent;
                color: ${palette.neutral[100]};
                border: ${border.normal} solid ${palette.neutral[100]};
            }
        }
    }

    &.icon {
        background: transparent;
        color: ${palette.primary[500]};
        border: ${border.normal} solid transparent;

        &:hover {
            background: ${palette.primary[100]};
            border: ${border.normal} solid ${palette.primary[100]};
        }
        &:focus {
            border: ${border.normal} solid ${palette.primary[300]};
        }
        &:active {
            background: ${palette.primary[300]};
            color: ${palette.primary[600]};
        }

        &:disabled, &.disabled {
            pointer-events: none;
            user-select: none;
          opacity: .5;
          pointer-events: none;
        }

        &.red {
            color: ${palette.destructive[500]};

            &:hover {
                background: ${palette.destructive[100]};
                border: ${border.normal} solid ${palette.destructive[100]};
            }
            &:focus {
                border: ${border.normal} solid ${palette.destructive[300]};
            }
            &:active {
                background: ${palette.destructive[300]};
                color: ${palette.destructive[600]};
            }
        }
    }

    &.text {
        background: transparent;
        color: ${palette.primary[500]};
        border: none;
        padding: 0;
        font-size: 14px;
        line-height: 20px;
        &:hover {
            background: transparent;
            border: none;
        }
        &:focus {
            border: none;
        }
        &:active {
            background: transparent;
            color: ${palette.primary[600]};
        }
        &:disabled, &.disabled {
            color: ${palette.primary[300]};
        }

        &.large {
            font-size: 16px;
            line-height: 24px;
        }
    }

    &.has-icons {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        gap: ${distances.tiny};
    }

    &.icon-20x20 {
        .icon {
            width: 20px;
            height: 20px;
        }
    }

    &.bg-white {
        background: white;
    }

    &.compact {
        padding: 0;
        min-height: 0;
    }
`;

export default ButtonLink;
