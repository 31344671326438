import styled from "styled-components/macro";
import { palette } from "../../styles/constants";
import { useAccordionContext, useAccordionItemContext } from "./Context";
import chevronSvg from "./icons/chevron.svg";

type AccordionControlProps = {
    children: React.ReactNode;
    withChevron?: boolean;
    disabled?: boolean;
    unstyled?: boolean;
    py?: string | number;
    as?: string;
    style?: React.CSSProperties;
};

export default function AccordionControl({
    children,
    withChevron = true,
    disabled,
    unstyled,
    py,
    style,
    as = "button",
}: AccordionControlProps) {
    const { value } = useAccordionItemContext();
    const ctx = useAccordionContext();
    const isActive = ctx.isItemActive(value);

    return (
        <Control
            id={ctx.getControlId(value)}
            data-active={isActive}
            role="button"
            aria-expanded={isActive}
            aria-controls={ctx.getRegionId(value)}
            onClick={disabled ? undefined : () => ctx.onChange(value)}
            data-unstyled={unstyled}
            style={{
                ...style,
                ...(py && {
                    paddingTop: py,
                    paddingBottom: py,
                }),
            }}
            type="button"
            as={as as any}
        >
            {typeof children === "function" ? children(isActive) : children}
            {withChevron && <Chevron data-active={isActive} />}
        </Control>
    );
}

const Control = styled.button`
    all: unset;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    gap: 8px;
    align-self: stretch;
    width: 100%;
    box-sizing: border-box;
    &[data-active='true'] {
        background-color: ${palette.neutral[50]};
    }
    &[data-unstyled='true'] {
        padding: 0;
        &[data-active='true'] {
            background-color: unset;
        }
    }
`;

const Chevron = styled.div`
    width: 20px;
    height: 20px;
    mask-image: url(${chevronSvg});
    mask-size: contain;
    mask-repeat: no-repeat;
    background-color: currentColor;
    transform: rotate(0deg);
    transition: transform 0.2s cubic-bezier(0.39, 0.575, 0.565, 1);
    &[data-active='true'] {
        transform: rotate(180deg);
    }
`;
