import styled, { keyframes } from "styled-components/macro";

import { distances, palette, zIndex } from "../../styles/constants";
import { AppBarContainer } from "../AppBar";
import Main from "../Main";
import Page from "../Page";
import TopBarPlaceholder from "./TopBar";

const PlaceholderApp = () => {
    return (
        <>
            <TopBarPlaceholder />
            <AppBarContainer placeholder />
            <Main>
                <Page title="" />
            </Main>
            <Backdrop />
        </>
    );
};

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const Grow = keyframes`
  100% {
    transform: scaleX(0.7)
  }
`;
const Shrink = keyframes`
  100% {
    transform: scaleX(0.3)
  }
`;

export const ContentMedium = styled.div`
    width: 80%;
    height: ${distances.small};
    margin-bottom: ${distances.small};
    background: rgba(0, 0, 0, 0.035);
    border-radius: 2px;
    animation: 12500ms ${Grow} ease-out 200ms infinite both alternate, 200ms ${fadeIn} ease-out;
    transform-origin: 0 50%;
`;

export const ContentLong = styled(ContentMedium)`
    width: 50%;
    height: 14px;
    background: rgba(0, 0, 0, 0.03);
    animation: 8300ms ${Grow} ease-out 200ms infinite both alternate, 200ms ${fadeIn} ease-out;
    transform-origin: 0 50%;
`;

export const ContentShort = styled(ContentMedium)`
    width: 60%;
    background: rgba(0, 0, 0, 0.04);
    animation: 10900ms ${Shrink} ease-out 200ms infinite alternate, 200ms ${fadeIn} ease-out;
    transform-origin: 0 50%;
`;

const Backdrop = styled.div`
    background: ${palette.neutral[900]}55;
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: ${zIndex.top};

    width: 100vw;
    height: 100vh;

    max-width: 100vw;

    &.centered {
        justify-content: center;
    }
`;

export default PlaceholderApp;
