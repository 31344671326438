import authStore from "./auth/accessToken/observables/authStore";
import { version } from "./env";
import { Causes, errorExternalStore } from "./errors";
import { expiresAt } from "./helpers/jwt";

type HttpHeaders = {
    [headerName: string]: string;
};

type ApiParams<T, P = unknown> = {
    method?: "GET" | "POST" | "PUT" | "DELETE";
    url: string;
    headers?: HttpHeaders;
    accountId?: string;
    json?: P;
    cacheBuster?: boolean;
    includeSystemHeaders?: boolean;
    signal?: AbortSignal;
    handlers?: Record<number, (data: T) => T>;
    useErrorStore?: boolean;
    /** If set to false, the return type will always be Response */
    parseResponse?: boolean;
    requestInit?: RequestInit;
};

type ErrorResponse = {
    error: {
        message: string;
        code?: string;
        errors?: ErrorResponse[];
    };
};

type ApiError<T = ErrorResponse> = {
    response: Response;
    data: T;
    url: string;
    method: string;
};

const statusCodes = {
    100: "Continue",
    101: "Switching Protocols",
    102: "Processing",
    200: "OK",
    201: "Created",
    202: "Accepted",
    203: "Non-authoritative Information",
    204: "No Content",
    205: "Reset Content",
    206: "Partial Content",
    207: "Multi-Status",
    208: "Already Reported",
    226: "IM Used",
    300: "Multiple Choices",
    301: "Moved Permanently",
    302: "Found",
    303: "See Other",
    304: "Not Modified",
    305: "Use Proxy",
    307: "Temporary Redirect",
    308: "Permanent Redirect",
    400: "Bad Request",
    401: "Unauthorized",
    402: "Payment Required",
    403: "Forbidden",
    404: "Not Found",
    405: "Method Not Allowed",
    406: "Not Acceptable",
    407: "Proxy Authentication Required",
    408: "Request Timeout",
    409: "Conflict",
    410: "Gone",
    411: "Length Required",
    412: "Precondition Failed",
    413: "Payload Too Large",
    414: "Request-URI Too Long",
    415: "Unsupported Media Type",
    416: "Requested Range Not Satisfiable",
    417: "Expectation Failed",
    421: "Misdirected Request",
    422: "Unprocessable Entity",
    423: "Locked",
    424: "Failed Dependency",
    426: "Upgrade Required",
    428: "Precondition Required",
    429: "Too Many Requests",
    431: "Request Header Fields Too Large",
    444: "Connection Closed Without Response",
    451: "Unavailable For Legal Reasons",
    499: "Client Closed Request",
    500: "Internal Server Error",
    501: "Not Implemented",
    502: "Bad Gateway",
    503: "Service Unavailable",
    504: "Gateway Timeout",
    505: "HTTP Version Not Supported",
    506: "Variant Also Negotiates",
    507: "Insufficient Storage",
    508: "Loop Detected",
    510: "Not Extended",
    511: "Network Authentication Required",
    599: "Network Connect Timeout Error",
};

export const getAuthorizationBearerHeader = (token: string) => {
    const headers: HttpHeaders = {
        Authorization: `Bearer ${token}`,
    };
    return headers;
};

const getSystemHeaders = () => {
    const headers: HttpHeaders = {
        "Dintero-System-Name": "backoffice",
        "Dintero-System-Version": `${version || ""}`,
    };
    return headers;
};

const addCacheBustParam = (url: string) => {
    if (url.indexOf("?") > -1) {
        return `${url}&_v=${getCacheBusterValue()}`;
    }
    return `${url}?_v=${getCacheBusterValue()}`;
};

const getCacheBusterValue = () => {
    return new Date().valueOf() + Math.random().toString(36).substring(7);
};

const defaultParams: ApiParams<unknown, unknown> = {
    method: "GET",
    url: "",
    accountId: "",
    json: undefined,
    cacheBuster: true,
    includeSystemHeaders: true,
    signal: undefined,
    useErrorStore: true,
    parseResponse: true,
};

const authTokenExpired = (headers: Headers | undefined) => {
    try {
        const authToken = headers?.get("Authorization") || "";
        const [bearer, token] = authToken.split(" ");
        if (bearer === "Bearer") {
            const expiry = expiresAt(token || "");
            return expiry < new Date();
        }
        // try to inspect token
    } catch (e) {
        console.error(e);
    }
    return false;
};

export const isApiFetchError = (error: unknown): error is ApiError => {
    return (
        typeof error === "object" &&
        error !== null &&
        "response" in error &&
        "data" in error
    );
};

const handleError = (error: unknown, useErrorStore: boolean | undefined) => {
    if (error instanceof Error) {
        errorExternalStore.dispatch("setError", {
            cause: Causes.FetchException,
            message: error.message,
        });
    }
    if (isApiFetchError(error)) {
        if (useErrorStore) {
            errorExternalStore.dispatch("setError", {
                cause: authTokenExpired(error.response.headers)
                    ? Causes.AccessTokenExpired
                    : Causes.UnhandledStatus,
                status: error.response.status,
                statusText:
                    error.response.statusText ||
                    statusCodes[
                        error.response.status as keyof typeof statusCodes
                    ],
                message:
                    typeof error.data === "string"
                        ? error.data
                        : JSON.stringify(error.data),
                "request-id":
                    error.response.headers.get("request-id") || undefined,
                url: error.url,
                method: error.method,
            });
        }
        throw error;
    }
    throw error;
};

/**
 * Straightforward fetch wrapper that adds system headers and authorization headers
 * @param params.method - HTTP method
 * @param params.url - URL to fetch
 * @param params.accountId - Account ID to fetch on behalf of
 * @param params.json - JSON payload to send
 * @param params.cacheBuster - Whether to add a cache buster to the URL
 * @param params.includeSystemHeaders - Whether to include system headers
 * @param params.signal - Abort signal
 * @param params.handlers - Response handlers
 * @returns JSON response
 * @throws Error if response is not OK
 * */
export const api = async <T, P = unknown>(params: ApiParams<T, P>) => {
    params = {
        ...(defaultParams as ApiParams<T, P>),
        ...params,
    };
    const {
        method,
        url,
        headers,
        accountId,
        json,
        cacheBuster,
        includeSystemHeaders,
        signal,
        useErrorStore,
        parseResponse,
        requestInit,
    } = params;
    const fetchHeaders = new Headers({
        "Content-Type": "application/json; charset=utf-8",
        Accept: "application/json",
    });
    if (!url || !method) {
        throw handleError(new Error("Missing URL or method"), useErrorStore);
    }

    if (includeSystemHeaders) {
        const systemHeaders = getSystemHeaders();
        for (const key in systemHeaders) {
            fetchHeaders.set(key, systemHeaders[key]);
        }
    }

    if (accountId) {
        const tokens = authStore.select((state) => state.tokens);
        if (tokens[accountId]) {
            const authorizationHeader = getAuthorizationBearerHeader(
                tokens[accountId],
            );
            for (const key in authorizationHeader) {
                fetchHeaders.set(key, authorizationHeader[key]);
            }
        }
    }

    if (headers) {
        for (const key in headers) {
            fetchHeaders.set(key, headers[key]);
        }
    }

    const fetchUrl = cacheBuster ? addCacheBustParam(url) : url;
    const fetchParams: RequestInit = {
        method,
        headers: fetchHeaders,
        signal,
        ...requestInit,
    };

    if (json) {
        fetchParams.body = JSON.stringify(json);
    }

    const response = await fetch(fetchUrl, fetchParams);
    if (!parseResponse) {
        return response as T;
    }

    const contentType = response.headers.get("content-type");
    const data = contentType?.includes("application/json")
        ? await response.json()
        : await response.text();

    if (!response.ok) {
        if (params.handlers?.[response.status]) {
            return params.handlers[response.status](data as T);
        }
        throw handleError({ response, data, url, method }, useErrorStore);
    }

    if (params.handlers?.[response.status]) {
        return params.handlers[response.status](data as T);
    }

    return data as T;
};
